export function resizeImage(file, width) {
  return new Promise((resolve, reject) => {
    // const file = document.querySelector("#upload").files[0]

    // if (!file) return

    const reader = new FileReader()

    reader.readAsDataURL(file)

    let srcEncoded = null

    reader.onload = function (event) {
      const imgElement = document.createElement("img")
      imgElement.src = event.target.result
      // document.querySelector("#input").src = event.target.result

      imgElement.onload = function (e) {
        const canvas = document.createElement("canvas")
        const MAX_WIDTH = width

        const scaleSize = MAX_WIDTH / e.target.width
        canvas.width = MAX_WIDTH
        canvas.height = e.target.height * scaleSize

        const ctx = canvas.getContext("2d")

        ctx.drawImage(e.target, 0, 0, canvas.width, canvas.height)

        srcEncoded = ctx.canvas.toDataURL(e.target, "image/jpeg")

        resolve(dataURLtoFile(srcEncoded, file.name))

        // document.querySelector("#output").src = srcEncoded
        // return srcEncoded
      }
    }
  })
}

export function dataURLtoFile(dataurl, filename) {
  const arr = dataurl.split(",")
  const mime = arr[0].match(/:(.*?);/)[1]
  const bstr = atob(arr[1])
  let n = bstr.length
  const u8arr = new Uint8Array(n)

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }

  return new File([u8arr], filename, { type: mime })
}

import { Icon } from "@components/Icons"
import React from "react"

import styles from "../style.module.less"

const ButtomCloseModal = () => (
  <button className={styles.buttonClose}>
    <Icon name="CloseModal" />
  </button>
)
export default ButtomCloseModal

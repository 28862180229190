import { Icon } from "@components/Icons"
import MyAccountModal from "@components/myAccountModal"
import TranslateWrapper from "@components/TranslateWrapper"
import translate from "@src/i18n"
import { Button } from "antd"
import cx from "classnames"
import React, { useState } from "react"

import styles from "./style.module.less"

export const SuccessModalStylized = ({
  visible,
  description,
  handleClose,
  buttonText,
  onClick,
  modalOption,
}) => {
  const handleClickButton = () => {
    if (onClick) {
      onClick()
      return
    }

    handleClose()
  }
  const switchRender = (option) => {
    switch (option) {
      case "sharedPost":
        return (
          <>
            <div className={styles.descriptionContainer}>
              <span className={styles.form_title}>
                {translate("sharedPost")}
              </span>
            </div>
            <img
              className={(styles.icon, styles.marginTop44)}
              src={require("@images/icons/sharedPost.svg")}
              alt="Post compartilhado com sucesso!"
            />
          </>
        )

      case "planCanceled":
        return (
          <>
            <div
              className={
                (styles.descriptionContainer, styles.widthTokenMessage)
              }
            >
              <span className={styles.form_title}>
                {translate("planCanceledWithSuccess")}
              </span>
            </div>
            <img
              className={(styles.icon, styles.marginTop44)}
              src={require("@images/icons/cancelPlanIcon.svg")}
              alt="Post compartilhado com sucesso!"
            />
          </>
        )

      case "tokenConfirmed":
        return (
          <>
            <div
              className={
                (styles.descriptionContainer, styles.widthTokenMessage)
              }
            >
              <span className={styles.form_title}>
                {translate("tokenConfirmed")}
              </span>
            </div>
            <img
              className={(styles.icon, styles.marginTop44)}
              src={require("@images/icons/tokenConfirmed.svg")}
              alt="Post compartilhado com sucesso!"
            />
          </>
        )

        default:
        return
    }
  }

  switchRender(modalOption)

  return (
    <MyAccountModal
      visible={visible}
      handleClose={handleClose}
      classname="successModal"
    >
      <TranslateWrapper>
        <div className={styles.mainContainer}>
          {switchRender(modalOption)}
          <Button
            type="primary"
            className={styles.button}
            onClick={handleClickButton}
          >
            {buttonText || translate("goToSite")}
          </Button>
        </div>
      </TranslateWrapper>
    </MyAccountModal>
  )
}
